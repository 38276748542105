import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import MainNavigation from '../../components/MainNavigation';
import DismissableBanner from '../../components/DismissableBanner';
import MainFooter from '../../components/MainFooter';
import FooterCTA from '../../components/Home/FooterCTA';
import HostJaneHostingHero from '../../components/HostJaneHostingHero';
import ServerPlan from '../../components/Hosting/ServerPlan';

import data from '../../../form-data/index';
import footerCtaData from '../../../site-data/home/footer-cta';
import {
	Alert as TopAlert,
	backgroundColor,
	textColor,
	backgroundGradient,
} from '../../../site-data/hosting/alert';
import { buttons } from '../../../site-data/hosting/main-hero/buttons';
import { vpsBoxData } from '../../../site-data/hosting/vps-box';
import { screenshotsData } from '../../../site-data/hosting/screenshots';
import Screenshots from '../../components/Hosting/Screenshots';
import FAQs from '../../components/Hosting/FAQs';
import { faqData } from '../../../site-data/hosting/faqs';
import SolidFeatures from '../../components/Hosting/SolidFeatures';
import CompareTable from '../../components/Hosting/CompareTable';
import solidFeaturesData from '../../../site-data/hosting/solid-features';
import compareTableData from '../../../site-data/hosting/compare-table';
import TabletNavigation from '../../components/Home/TabletNavigation';

export default function HostingIndex(props) {
	const {
		data: { mainHero, hostingScreenshots, hostingFAQs },
	} = props;
	return (
		<Layout {...props} hasFooter={false} banner={data.banner} fullWidth>
			<SEO
				title="Managed VPS Hosting | Virtual Private Servers in AWS Datacenters"
				description="Buy high-quality VPS managed on AWS EC2 bare-metal compute. Configured for cPanel, Plesk, or WordPress with Yoast SEO."
			/>
			
			<MainNavigation />
			<TabletNavigation />
			<HostJaneHostingHero
				heading={mainHero.frontmatter.heading}
				content={mainHero.body}
				imageFluidData={
					mainHero.frontmatter.image.childImageSharp.fluid
				}
				linkLabel={mainHero.frontmatter.linkLabel}
				linkHash="#hostjane-buy-hosting"
				buttons={buttons}
			/>
			<ServerPlan
				heading={vpsBoxData.heading}
				subHeading={vpsBoxData.subheading}
				id="hostjane-buy-hosting"
				boxes={vpsBoxData.boxes}
				icon={data.alertIcon}
				message={data.alertMessage}
				title={data.alertTitle}
				isHosting
			/>

			<SolidFeatures
				features={solidFeaturesData.features}
				heading={solidFeaturesData.heading}
				linkIcon={solidFeaturesData.linkIcon}
			/>
			<CompareTable
				heading={compareTableData.heading}
				subHeading={compareTableData.subHeading}
				shortBottomDescription={compareTableData.shortBottomDescription}
				buttonText={compareTableData.buttonText}
				tableData={compareTableData.tableData}
			/>
			<Screenshots
				heading={screenshotsData.heading}
				subHeading={screenshotsData.subHeading}
				screenshots={hostingScreenshots.edges}
				id="hostjane-manage-server"
			/>
			<FAQs
				id="hostjane-faqs"
				faqs={hostingFAQs.edges}
				heading={faqData.title}
			/>
			<FooterCTA data={footerCtaData} />
			<MainFooter />
		</Layout>
	);
}

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				nav {
					path
					icon
					title
					desc
				}
			}
		}
		mainHero: mdx(
			fileAbsolutePath: {
				glob: "**/site-data/hosting/main-hero/content.mdx"
			}
		) {
			body
			rawBody
			frontmatter {
				heading
				linkLabel
				image {
					childImageSharp {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
		hostingScreenshots: allMdx(
			filter: {
				fileAbsolutePath: {
					glob: "**/site-data/hosting/screenshots/contents/**"
				}
			}
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						image {
							childImageSharp {
								fluid(maxWidth: 800) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
					body
				}
			}
		}
		hostingFAQs: allMdx(
			filter: {
				fileAbsolutePath: {
					glob: "**/site-data/hosting/faqs/contents/**"
				}
			}
			sort: { order: ASC, fields: frontmatter___order }
		) {
			pageInfo {
				totalCount
			}
			edges {
				node {
					id
					frontmatter {
						title
					}
					body
				}
			}
		}
	}
`;
