import React, { useState } from 'react';
import scrollToElement from 'scroll-to-element';
import styled from 'styled-components';
import { ReactComponent as Tick } from '../../../svgs/icons/tick-icon.svg';
import ButtonLink from '../../ButtonLink';
import GradientHeading from '../../GradientHeading';

const CompareTableWrapper = styled.section`
	p {
		margin: 0;
	}
	margin-bottom: 112px;
	.hostjane-main-CompareTable {
		&__vps-hosting-btn {
			width: 260px;
			margin: 0 auto;
		}
		&__build-server {
			padding: 48px 0px;
			text-align: center;
			p {
				color: #000000;
				font-weight: 700;
				margin-bottom: 16px;
			}
			button {
				height: 48px;
				font-size: 1.125rem;
				line-height: 46px;
				border-color: #e0452f;
				color: #fff;
				text-shadow: 0 -1px 1px #d8452e;
				display: inline-block;
				padding: 0 16px;
				border-radius: 5px;
				font-family: inherit;
				font-weight: 700;
				line-height: 36px;
				text-align: center;
				white-space: nowrap;
				cursor: pointer;
				vertical-align: middle;
				touch-action: manipulation;
				border: 1px solid #b8b8b8;
				background: #f14c2e linear-gradient(#f14c2e, #d8452e);
				&:hover {
					text-decoration: none;
					background: #d8452e linear-gradient(#d8452e, #c73b25);
				}
				&:active {
					background: #d8452e linear-gradient(#d8452e, #c73b25);
				}
				&:focus {
					background: #d8452e linear-gradient(#d8452e, #c73b25);
					border-color: #75b9f0;
					outline: none;
					box-shadow: 0 0 8px #97cbf4;
				}
			}
		}
	}
	.hostjane-main-CompareTable__show-more {
		text-transform: capitalize;
		font-size: 1.25rem;
		background: #f2f2f2;
		line-height: 41px;
		color: #a0a1a3;
		border: 0;
		height: 41px;
		margin-top: 16px;
		font-weight: 500;
		min-width: 160px;
		display: inline-block;
		padding: 0 16px;
		border-radius: 5px;
		font-family: inherit;
		text-align: center;
		white-space: nowrap;
		cursor: pointer;
		vertical-align: middle;
		touch-action: manipulation;
		text-shadow: 0 -1px 1px #ededed;
	}
	.hostjane-main-CompareTable__heading {
		text-align: center;
		font-size: 1.125rem;
		line-height: 1.33;
		text-align: center;
		color: #000000;

		h1 {
			line-height: 1.4;
			margin: 0 auto;
			padding: 0;
			max-width: 90%;
			text-align: center;
			color: #fff;
			background: linear-gradient(
				to right,
				rgb(146, 23, 77) 9.38%,
				rgb(228, 29, 92) 88.54%
			);
			background-clip: text;
			-webkit-background-clip: text;
			text-fill-color: transparent;
			-webkit-text-fill-color: transparent;
			font-weight: 400;
			-webkit-letter-spacing: -0.01em;
			-moz-letter-spacing: -0.01em;
			-ms-letter-spacing: -0.01em;
			letter-spacing: -0.01em;
		}
		p {
			margin-top: 16px;
			font-weight: 300;
		}
	}

	.hostjane-main-CompareTable__table-main {
		overflow-x: auto;
		overflow-y: hidden;
		margin: 0 auto;
		padding: 0 66px;
		max-width: 1440px;
		width: 100%;
		padding-top: 48px;
		@media only screen and (max-width: ${props =>
				props.theme.breakpoints.tablet}) {
			padding: 0 20px;
		}
		.hostjane-main-CompareTable__table {
			&.collapsible-table {
				max-height: 270px;
				transition: all 0.5s ease-in-out;
				&.d-none {
					max-height: 0px;
					overflow: hidden;
				}
			}
			width: 100%;
			line-height: 1.25em;
			border-collapse: collapse;
			tr {
				th:nth-child(3),
				td:nth-child(3) {
					background-color: #fbefca;
				}
				th,
				td {
					text-align: center;
					border: 1px solid #d6d6d6;
				}
			}
			.hostjane-main-CompareTable__head {
				tr {
					th {
						font-size: 1.25rem;
						padding: 12px;
						padding-bottom: 24px;
						min-width: 200px;
						color: #000000;
						// width: 400px;
						max-width: 200px;
						&:last-child {
							border-radius: 8px 8px 0 0;
						}
						border-color: transparent transparent #d6d6d6;
						p {
							font-size: 22px;
							color: #000000;
							margin-bottom: 15px;
						}
					}
				}
				.hostjane-main-CompareTable__empty-column {
				}
			}
			.hostjane-main-CompareTable__body {
				&__tick {
					fill: #fbbc0c;
					width: 19px;
					height: 21px;
				}
				tr {
					th {
						text-align: left;
						font-size: 1.25rem;
						padding: 12px;
						color: #000000;
						line-height: 28px;
						min-width: 200px;
						border-color: transparent transparent #d6d6d6;
						// width: 400px;
						max-width: 200px;
						p {
							display: inline-block;
						}
						.hostjane-main-CompareTable__tooltip {
							display: inline-block;
							position: relative;
							&:hover {
								.hostjane-main-CompareTable__tooltip-wrap {
									display: block;
									transform: translate3d(-63px, -138px, 0px);
								}
							}
							.hostjane-main-CompareTable__question {
								cursor: pointer;
								width: 21px;
								height: 21px;
								line-height: 21px;
								font-weight: 500;
								font-size: 0.95rem;
								border-radius: 50%;
								background: #fbbc0c;
								color: #fff;
								text-align: center;
								margin-left: 12px;
								display: inline-block;
							}
							.hostjane-main-CompareTable__tooltip-wrap {
								will-change: transform;
								display: none;
								position: absolute;
								top: 0;
								left: 0;
								padding: 6px;
								text-align: left;
								cursor: auto;
								z-index: 999;
								@media only screen and (max-width: 576px) {
									left: 52px;
								}
								.hostjane-main-CompareTable__tooltip__arrow {
									bottom: 0;
									left: 80px;
									display: block;
									position: absolute;
									width: 12px;
									height: 12px;
									-webkit-transform: rotate(45deg);
									transform: rotate(45deg);
									background: #000000;
									@media only screen and (max-width: 576px) {
										left: 27px;
									}
								}
								.hostjane-main-CompareTable__tooltip__content {
									display: inline-block;
									width: 260px;
									padding: 24px 28px;
									border-radius: 5px;
									background: #000000;
									color: #fff;
									font-size: 18px;
									font-weight: 500;
									line-height: 1.5;
									text-transform: none;
									box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
								}
							}
						}
					}

					td {
						padding: 12px;
						font-weight: 500;
						line-height: 28px;
						min-width: 200px;
						// width: 400px;
						max-width: 200px;
						color: #000000;
						border-color: transparent transparent #d6d6d6;
						font-size: 1.25rem;
						padding: 12px;
						p {
							font-size: 16px;
							font-weight: 400;
							margin: 0 auto;
							line-height: 14px;
						}
					}
				}
			}
			&__collapsible-table-main {
				&.active {
					height: 270px;
					@media only screen and (max-width: 1132px) {
						height: 343px;
					}
					@media only screen and (max-width: 1098px) {
						height: 343px;
					}
				}
				overflow: hidden;
				&.non-active {
					height: 0px;
					overflow: hidden;
				}
				&.overflow-active {
					overflow: initial;
				}

				transition: height 0.5s ease-in-out;
			}
		}
	}
`;

export default function CompareTable({
	heading,
	subHeading,
	shortBottomDescription,
	buttonText,
	tableData,
}) {
	const [tableExtend, setTableExtend] = useState(false);
	const [overflowActive, setOverflowActive] = useState(false);

	return (
		<CompareTableWrapper
			className="hostjane-main-CompareTable"
			id="compare-plans"
		>
			<GradientHeading heading={heading} subHeading={subHeading} />
			<div className="hostjane-main-CompareTable__table-main">
				<table className="hostjane-main-CompareTable__table">
					<thead className="hostjane-main-CompareTable__head">
						<tr>
							<th className="hostjane-main-CompareTable__empty-column" />
							<th>
								<p>Plesk VPS</p><p><a href="/vps/?appType=0&app=1">Select plan</a></p>
								$22.95/mo
							</th>
							<th>
								<p>cPanel VPS</p><p><a href="/vps/?appType=0&app=0">Select plan</a></p>
								$22.95/mo
							</th>
							<th>
								<p>WordPress VPS</p><p><a href="/vps/?appType=0&app=2">Select plan</a></p>
								$22.95/mo
							</th>
						</tr>
					</thead>
					<tbody className="hostjane-main-CompareTable__body">
						{[
							{
								data: [
									<a href="https://cloud.hostjane.com/hosting/cart.php?a=confproduct&i=1">Place order</a>,
									<a href="https://cloud.hostjane.com/hosting/cart.php?a=confproduct&i=1">Place order</a>,
									<a href="https://cloud.hostjane.com/hosting/cart.php?a=confproduct&i=1">Place order</a>,
								],
							},
							{
								data: [
									<Tick className="hostjane-main-CompareTable__body__tick" />,
									<Tick className="hostjane-main-CompareTable__body__tick" />,
									<Tick className="hostjane-main-CompareTable__body__tick" />,
								],
							},
							{
								data: [
									<Tick className="hostjane-main-CompareTable__body__tick" />,
									<Tick className="hostjane-main-CompareTable__body__tick" />,
									<Tick className="hostjane-main-CompareTable__body__tick" />,
								],
							},
							{
								data: [
									<Tick className="hostjane-main-CompareTable__body__tick" />,
									<Tick className="hostjane-main-CompareTable__body__tick" />,
									<Tick className="hostjane-main-CompareTable__body__tick" />,
								],
							},
							{
								data: [
										'Up to 160 GB SSD',
										'Up to 160 GB SSD',
										'Up to 160 GB SSD',
								],
							},
							
						].map(({ data }, index) => {
							return (
								<tr>
									<th>
										<p>{tableData?.[index]?.title} </p>

										<div className="hostjane-main-CompareTable__tooltip">
											<p className="hostjane-main-CompareTable__question">
												?
											</p>
											<div className="hostjane-main-CompareTable__tooltip-wrap">
												<div className="hostjane-main-CompareTable__tooltip__arrow" />
												<div className="hostjane-main-CompareTable__tooltip__content">
													<p>
														{
															tableData?.[index]
																?.tooltip
														}
													</p>
												</div>
											</div>
										</div>
									</th>
									{typeof data[0] == 'object' ? (
										<td>{data[0]}</td>
									) : (
										<td
											dangerouslySetInnerHTML={{
												__html: data[0],
											}}
										/>
									)}
									{typeof data[1] == 'object' ? (
										<td>{data[1]}</td>
									) : (
										<td
											dangerouslySetInnerHTML={{
												__html: data[1],
											}}
										/>
									)}
									{typeof data[2] == 'object' ? (
										<td>{data[2]}</td>
									) : (
										<td
											dangerouslySetInnerHTML={{
												__html: data[2],
											}}
										/>
									)}
								</tr>
							);
						})}
					</tbody>
				</table>
				<div
					className={`hostjane-main-CompareTable__table__collapsible-table-main ${
						tableExtend ? 'active' : 'non-active'
					}
					${overflowActive ? 'overflow-active' : ''}
					`}
				>
					{/* <Collapse isOpen={tableExtend}> */}
					<table className="hostjane-main-CompareTable__table collapsible-table">
						<tbody className="hostjane-main-CompareTable__body">
							{[
								{
									data: [
										'Up to 5TB data inbound',
										'Up to 5TB data inbound',
										'Up to 5TB data inbound',
									],
								},
								{
									data: [
											'Fast tickets + Telegram <p>24/7 incident</p>',
											'Fast tickets + Telegram <p>24/7 incident</p>',
											'Fast tickets + Telegram <p>24/7 incident</p>',
									],
								},
								{
									data: [
										<Tick className="hostjane-main-CompareTable__body__tick" />,
										<Tick className="hostjane-main-CompareTable__body__tick" />,
										<Tick className="hostjane-main-CompareTable__body__tick" />,
									],
								},
								{
									data: [
										<Tick className="hostjane-main-CompareTable__body__tick" />,
										<Tick className="hostjane-main-CompareTable__body__tick" />,
										<Tick className="hostjane-main-CompareTable__body__tick" />,
									],
								},
							].map(({ data }, index) => {
								return (
									<tr>
										<th>
											<p>
												{tableData?.[5 + index]?.title}
											</p>

											<div className="hostjane-main-CompareTable__tooltip">
												<p className="hostjane-main-CompareTable__question">
													?
												</p>
												<div className="hostjane-main-CompareTable__tooltip-wrap">
													<div className="hostjane-main-CompareTable__tooltip__arrow" />
													<div className="hostjane-main-CompareTable__tooltip__content">
														<p>
															{
																tableData?.[
																	5 + index
																]?.tooltip
															}
														</p>
													</div>
												</div>
											</div>
										</th>
										{typeof data[0] == 'object' ? (
											<td>{data[0]}</td>
										) : (
											<td
												dangerouslySetInnerHTML={{
													__html: data[0],
												}}
											/>
										)}
										{typeof data[1] == 'object' ? (
											<td>{data[1]}</td>
										) : (
											<td
												dangerouslySetInnerHTML={{
													__html: data[1],
												}}
											/>
										)}
										{typeof data[2] == 'object' ? (
											<td>{data[2]}</td>
										) : (
											<td
												dangerouslySetInnerHTML={{
													__html: data[2],
												}}
											/>
										)}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				{/* </Collapse> */}
				<button
					type="button"
					className="hostjane-main-CompareTable__show-more"
					onClick={() => {
						setTableExtend(!tableExtend);
						if (tableExtend) {
							setOverflowActive(false);
						} else {
							setTimeout(() => {
								setOverflowActive(true);
							}, 200);
						}
					}}
				>
					{!tableExtend ? '+ Show More' : '- Show Less'}
				</button>
			</div>
			<div className="hostjane-main-CompareTable__build-server">
				<p>{shortBottomDescription}</p>
				<button
					type="button"
					onClick={() => {
						scrollToElement('#hostjane-buy-hosting', {
							offset: 0,
							duration: 1500,
						});
					}}
				>
					{buttonText}
				</button>
			</div>
		</CompareTableWrapper>
	);
}
